// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyDI7DLGiG9Hn0ImyFG3-olHwNgRAozbLnA",
    authDomain: "warping-manager.firebaseapp.com",
    // databaseURL: "https://(default).firebaseio.com",
    projectId: "warping-manager",
    storageBucket: "warping-manager.appspot.com",
    messagingSenderId: "392146008162",
    appId: "1:392146008162:web:1ff606decf78eaadac393b"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const firebaseAuth = getAuth()
// Initialize Firebase
