import React, { useEffect, useState } from "react";
import { db } from "../../configs/fireBaseConfig";
import { DB_COLLECTION, ROUTES, USER_TYPE } from "../../common/constants";
import { doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { useParams } from "react-router";
import { VALIDATION } from "../../common/validation";
import { toastError, toastSuccess } from "../../common/utils";
import { createUserWithEmailAndPassword, getAuth } from "@firebase/auth";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import InputField from "../../common/InputField";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../common/CommonButton";

let userInfo = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: null,
};

function EditUser() {
  const navigate = useNavigate();

  let { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [mainLoader, setMainLoader] = useState(true)

  useEffect(() => {
    if (id !== "new") {
      fetchUserDetails();
    } else {
      setLoader(false);
      setMainLoader(false)
    }
  }, []);

  let schema =
    id === "new"
      ? yup.object().shape({
        name: VALIDATION.TEXT_INPUT_NAME,
        email: VALIDATION.EMAIL,
        password: VALIDATION.PASSWORD,
        confirmPassword: VALIDATION.CONFIRM_PASSWORD,
        role: yup.string().required(),
      })
      : yup.object().shape({
        name: VALIDATION.TEXT_INPUT_NAME,
        email: VALIDATION.EMAIL,
        role: yup.string().required(),
      });

  const fetchUserDetails = async () => {
    const query = doc(db, DB_COLLECTION.USERS, id);
    const document = await getDoc(query);
    if (document.exists()) {
      const userData = document.data();
      userInfo = {
        email: userData.email,
        name: userData.name,
        role: userData.role,
      };
    }
    setMainLoader(false)
    setLoader(false);
  };

  const SignUpForm = useFormik({
    enableReinitialize: true,
    initialValues: userInfo,
    onSubmit: (values) => {
      onFinish(values);
    },
    validationSchema: schema,
  });

  const onFinish = async (formData) => {
    setLoader(true)
    try {
      if (id === "new") {
        const auth = getAuth();
        const res = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );
        const userData = res.user;
        await setDoc(doc(db, DB_COLLECTION.USERS, userData.uid), {
          uid: userData.uid,
          name: formData.name,
          role: formData.role,
          email: userData.email,
          emailVerified: userData.emailVerified,
          createdAt: userData.metadata.createdAt,
        });
      } else {
        await setDoc(
          doc(db, DB_COLLECTION.USERS, id),
          {
            // uid: userData.uid,
            name: formData.name,
            role: formData.role,
            // email: userData.email,
            // emailVerified: userData.emailVerified,
            // createdAt: userData.metadata.createdAt,
            updatedAt: Date.now(),
          },
          { merge: true }
        );
        SignUpForm.setFieldValue({
          name: formData.name,
          role: formData.role,
        });
        SignUpForm.resetForm(0);
        toastSuccess("User updated successfully");

      }
      navigate(ROUTES.LIST_USER);
      setLoader(false);
    } catch (error) {
      /* show message user already exist */
      let errorMsg;
      if (error.code === "auth/email-already-in-use") {
        errorMsg = "Email is already registered";
      }
      setLoader(false)
      toastError(errorMsg);
    }
  };
  if (mainLoader) {
    return (
      <div>
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <section className="page-section">
      <div className="data-section">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="data-table-header">
                <h2>EditUser</h2>
                <Link to={ROUTES.LIST_USER}>
                  <Button>Back</Button>
                </Link>
              </div>
            </Col>
            <Col sm={12} md={{ span: 4, offset: 4 }}>
              <Form name="basic" onSubmit={SignUpForm.handleSubmit}>
                <InputField
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  onChange={SignUpForm.handleChange}
                  isValid={SignUpForm.touched.name && !SignUpForm.errors.name}
                  isInvalid={SignUpForm.errors.name}
                  value={SignUpForm.values.name}
                />
                <InputField
                  label="Email"
                  name="email"
                  type="email"
                  disabled={id !== "new"}
                  onChange={SignUpForm.handleChange}
                  placeholder="Email"
                  isValid={SignUpForm.touched.email && !SignUpForm.errors.email}
                  isInvalid={SignUpForm.errors.email}
                  value={SignUpForm.values.email}
                />
                {id == "new" && (
                  <>
                    <InputField
                      label="Password"
                      name="password"
                      type="password"
                      onChange={SignUpForm.handleChange}
                      placeholder="Password"
                      isValid={
                        SignUpForm.touched.password &&
                        !SignUpForm.errors.password
                      }
                      isInvalid={SignUpForm.errors.password}
                    />
                    <InputField
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      onChange={SignUpForm.handleChange}
                      placeholder="Confirm Password"
                      isInvalid={SignUpForm.errors.confirmPassword}
                    />
                  </>
                )}
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mt-4">
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      label="Admin"
                      name="role"
                      value={USER_TYPE.ADMIN}
                      checked={USER_TYPE.ADMIN === SignUpForm.values.role}
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      label="Supervisor"
                      name="role"
                      type={type}
                      value={USER_TYPE.SUPERVISOR}
                      checked={USER_TYPE.SUPERVISOR === SignUpForm.values.role}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      name="role"
                      value={USER_TYPE.WARPER}
                      checked={USER_TYPE.WARPER === SignUpForm.values.role}
                      label="Warper"
                      type={type}
                      id={`inline-${type}-3`}
                    />
                  </div>
                ))}
                <Form.Group className="form-btn">
                  <CommonButton disabled={!SignUpForm.dirty}
                    type="submit"
                    className="w-100 primary-btn" loader={loader} label={id === "new" ? "create user" : "Save and update"} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default EditUser;
