import React, { useEffect, useState } from "react";
import { db } from "../../configs/fireBaseConfig";
import {
  DB_COLLECTION,
  ORDER_STATUS,
  REGULAR_DATE_FORMAT,
  ROUTES,
  USER_TYPE,
} from "../../common/constants";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router";
import { VALIDATION } from "../../common/validation";
import { toastError, toastSuccess } from "../../common/utils";
import { createUserWithEmailAndPassword, getAuth } from "@firebase/auth";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InputField from "../../common/InputField";
import { Link } from "react-router-dom";
import { useAuth } from "../../authComponent/authContext";
import CommonButton from "../../common/CommonButton";
import dayjs from "dayjs";

let orderDetails = {
  party_name: "",
  address: "",
  rate: "",
  yarn_type: "",
  total_beams: "",
  total_ends: "",
  mtr: "",
  pano: "",
  remarks: "",
  invoice_id: "",
  // status : 'PENDING | PLANNED | WORKING | COMPLETED| CANCELLED',
  // warperName: '',
  supervisor_remarks: "",
  beam_details: [{ ends: "", beams: "", spools: "" }],
  prepareSwitch: false,
  orderStartTime: "",
  orderEndsTime: ""
  // createdAt: 'string'
  //   updatedAt: "string",
};

const initialSchema = yup.object().shape({
  party_name: VALIDATION.TEXT_INPUT_NAME,
  address: VALIDATION.TEXT_INPUT_NAME,
  rate: VALIDATION.NUMBER_INPUT("Please enter rate"),
  yarn_type: VALIDATION.TEXT_INPUT_NAME,
  total_beams: VALIDATION.NUMBER_INPUT("Please enter total ordered beams"),
  total_ends: VALIDATION.NUMBER_INPUT("Please enter ends per beam"),
  mtr: VALIDATION.NUMBER_INPUT("Please enter meter"),
  pano: VALIDATION.NUMBER_INPUT("Please enter pano (width)"),
  prepareSwitch: yup.boolean(),
  beam_details: yup.array().when("prepareSwitch", {
    is: true,
    then: (schema) =>
      schema
        .of(
          yup.object().shape({
            ends: yup.number().required("Ends is required"),
            beams: yup.number().required("Beams is required"),
            spools: yup.number(),
          })
        )
        .required("Beam details are required when prepareSwitch is on."),
  }),
});
function EditOrder() {
  const navigate = useNavigate();
  const { contextValue } = useAuth();
  const userRole = contextValue.userInfo.role;
  let { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [ordersInfo, setOrdersInfo] = useState(orderDetails);

  useEffect(() => {
    if (id !== "new") {
      fetchOrderDetails();
    } else {
      setLoader(false);
    }
  }, []);

  const fetchOrderDetails = async () => {
    const query = doc(db, DB_COLLECTION.ORDERS, id);
    const document = await getDoc(query);
    if (document.exists()) {
      const orderInfo = document.data();
      // orderInfo.prepareSwitch = orderInfo.status === ORDER_STATUS.PLANNED;
      setOrdersInfo({ ...orderInfo });
    }
    setLoader(false);
  };

  const orderForm = useFormik({
    enableReinitialize: true,
    initialValues: ordersInfo,
    validationSchema: initialSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      onFinish(values);
    },
  });

  const onFinish = async (formData) => {
    let prepareData = {
      ...formData,
      createdAt: Date.now(),
      status: formData.prepareSwitch
        ? ORDER_STATUS.PLANNED
        : ORDER_STATUS.PENDING,
    };
    // delete prepareData.prepareSwitch;
    try {
      if (id === "new") {
        const query = doc(
          db,
          DB_COLLECTION.COUNTER,
          DB_COLLECTION.COUNTER_REFERENCE
        );
        const getCounter = await getDoc(query);
        let invoice_id;
        const counter = getCounter.data();
        if (counter) {
          invoice_id = counter.counter + 1;
          await setDoc(
            doc(db, DB_COLLECTION.COUNTER, DB_COLLECTION.COUNTER_REFERENCE),
            { counter: invoice_id },
            { merge: true }
          );
        } else {
          await setDoc(
            doc(db, DB_COLLECTION.COUNTER, DB_COLLECTION.COUNTER_REFERENCE),
            { counter: 1000 }
          );
        }
        prepareData.invoice_id = invoice_id;
        await addDoc(collection(db, DB_COLLECTION.ORDERS), prepareData);
        toastSuccess("Order created successfully");
      } else {
        await setDoc(
          doc(db, DB_COLLECTION.ORDERS, id),
          {
            ...prepareData,
            updatedAt: Date.now(),
          },
          { merge: true }
        );
        toastSuccess("Order updated successfully");
      }
      orderForm.resetForm();
      navigate(ROUTES.LIST_ORDER);
    } catch (error) {
      console.log(error);
      /* show message user already exist */
      let errorMsg;
      if (error.code === "auth/email-already-in-use") {
        errorMsg = "Email is already registered";
      }
      toastError(errorMsg);
    }
  };

  const handleCancelOrder = async () => {
    // if (ordersInfo.status === ORDER_STATUS.WORKING || ordersInfo.status === ORDER_STATUS.COMPLETED) {
    //   toastError('This order is already in working or completed ')
    //   return
    // }
    await setDoc(
      doc(db, DB_COLLECTION.ORDERS, id),
      {
        status: ORDER_STATUS.CANCELLED,
        updatedAt: Date.now(),
      },
      { merge: true }
    );
    toastSuccess("Order cancelled successfully");
    navigate(ROUTES.LIST_ORDER);
  };

  /* dynamic fields function start */

  const handleAddFields = (index) => {
    let getBeamDetails = orderForm.values.beam_details;
    // getBeamDetails.splice(index, 0, { ends: "", beams: '', spools: "" })

    getBeamDetails.push({ ends: "", beams: "", spools: "" });
    orderForm.setFieldValue("beam_details", getBeamDetails);
  };

  const handleRemoveFields = (index) => {
    let getBeamDetails = orderForm.values.beam_details;
    getBeamDetails.splice(index, 1);
    orderForm.setFieldValue("beam_details", getBeamDetails);
  };

  /* dynamic fields function start */
  console.log(orderForm.values)
  return (
    <section className="page-section">
      <div className="data-section">
        <Container fluid>
          <Row>
            <Col sm={12}>
              <div className="data-table-header">
                <h2>{id !== 'new' ? "Edit Order" : "Create order"}</h2>
                <Link to={ROUTES.LIST_ORDER}>
                  <Button>Back</Button>
                </Link>
              </div>
            </Col>
            <Col sm={12}>
              {id !== 'new' && <div>Order created on : {dayjs(orderForm.values.createdAt).format(REGULAR_DATE_FORMAT)}</div>}
              <Form name="basic" onSubmit={orderForm.handleSubmit}>
                <Row>
                  <Col md={12}>
                    <InputField
                      label="Party Name"
                      name="party_name"
                      type="text"
                      placeholder="Party Name"
                      onChange={orderForm.handleChange}
                      isValid={
                        orderForm.touched.party_name &&
                        !orderForm.errors.party_name
                      }
                      isInvalid={orderForm.errors.party_name}
                      value={orderForm.values.party_name}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col sm={12}>
                    <InputField
                      label="Delivery address"
                      name="address"
                      type="text"
                      placeholder="Delivery address"
                      onChange={orderForm.handleChange}
                      isValid={
                        orderForm.touched.address && !orderForm.errors.address
                      }
                      isInvalid={orderForm.errors.address}
                      value={orderForm.values.address}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                      as="textarea"
                      style={{ height: "70px" }}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Rate"
                      name="rate"
                      type="number"
                      onChange={orderForm.handleChange}
                      placeholder="Rate"
                      isValid={orderForm.touched.rate && !orderForm.errors.rate}
                      isInvalid={orderForm.errors.rate}
                      value={orderForm.values.rate}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Yarn type"
                      name="yarn_type"
                      type="yarn_type"
                      onChange={orderForm.handleChange}
                      placeholder="Yarn type"
                      isValid={
                        orderForm.touched.yarn_type &&
                        !orderForm.errors.yarn_type
                      }
                      isInvalid={orderForm.errors.yarn_type}
                      value={orderForm.values.yarn_type}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Total beams"
                      name="total_beams"
                      type="number"
                      onChange={orderForm.handleChange}
                      placeholder="No. of beams"
                      isValid={
                        orderForm.touched.total_beams &&
                        !orderForm.errors.total_beams
                      }
                      isInvalid={orderForm.errors.total_beams}
                      value={orderForm.values.total_beams}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Total ends"
                      name="total_ends"
                      type="number"
                      onChange={orderForm.handleChange}
                      placeholder="No. of ends (Tar)"
                      isValid={
                        orderForm.touched.total_ends &&
                        !orderForm.errors.total_ends
                      }
                      isInvalid={orderForm.errors.total_ends}
                      value={orderForm.values.total_ends}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Meter"
                      name="mtr"
                      type="number"
                      onChange={orderForm.handleChange}
                      placeholder="Meter"
                      isValid={orderForm.touched.mtr && !orderForm.errors.mtr}
                      isInvalid={orderForm.errors.mtr}
                      value={orderForm.values.mtr}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Pano"
                      name="pano"
                      type="number"
                      onChange={orderForm.handleChange}
                      placeholder="Pano (width)"
                      isValid={orderForm.touched.pano && !orderForm.errors.pano}
                      isInvalid={orderForm.errors.pano}
                      value={orderForm.values.pano}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Remarks"
                      name="remarks"
                      type="text"
                      placeholder="Remarks"
                      onChange={orderForm.handleChange}
                      isValid={
                        orderForm.touched.remarks && !orderForm.errors.remarks
                      }
                      isInvalid={orderForm.errors.remarks}
                      value={orderForm.values.remarks}
                      disabled={userRole === USER_TYPE.SUPERVISOR}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      label="Supervisor remarks"
                      name="supervisor_remarks"
                      type="text"
                      placeholder="Supervisor remarks"
                      onChange={orderForm.handleChange}
                      // isValid={orderForm.touched.supervisor_remarks && !orderForm.errors.supervisor_remarks}
                      isInvalid={orderForm.errors.supervisor_remarks}
                      value={orderForm.values.supervisor_remarks}
                    />
                  </Col>
                  <Col sm={12}>
                    <Form.Check
                      name="prepareSwitch"
                      onChange={() => orderForm.setFieldValue("prepareSwitch", !orderForm.values.prepareSwitch)}
                      type="switch"
                      checked={orderForm.values.prepareSwitch}
                      id="custom-switch"
                      label="Plan Order"
                    />
                  </Col>
                </Row>
                {orderForm.values.prepareSwitch && (
                  <>
                    <h3>Beams Plan</h3>
                    {orderForm?.values?.beam_details?.map(
                      (item, index, ary) => (
                        <>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <InputField
                              label="Beams"
                              name="beams"
                              type="number"
                              onChange={(e) =>
                                orderForm.setFieldValue(
                                  `beam_details[${index}]beams`,
                                  e.target.value
                                )
                              }
                              placeholder="Beams"
                              isInvalid={
                                orderForm?.errors?.beam_details?.[index]?.beams
                              }
                              value={item.beams}
                            />
                            <InputField
                              label="Ends"
                              name="ends"
                              type="number"
                              onChange={(e) =>
                                orderForm.setFieldValue(
                                  `beam_details[${index}]ends`,
                                  e.target.value
                                )
                              }
                              placeholder="ends"
                              isInvalid={
                                orderForm?.errors?.beam_details?.[index]?.ends
                              }
                              value={item.ends}
                            />
                            <InputField
                              label="Spools"
                              name="spools"
                              type="number"
                              onChange={(e) =>
                                orderForm.setFieldValue(
                                  `beam_details[${index}]spools`,
                                  e.target.value
                                )
                              }
                              placeholder="Spools"
                              isInvalid={
                                orderForm?.errors?.beam_details?.[index]?.spools
                              }
                              value={item.spools}
                            />
                            {ary.length > 1 && (
                              <Button onClick={() => handleRemoveFields(index)}>
                                Remove
                              </Button>
                            )}
                          </div>
                          {ary.length == index + 1 && (
                            <Button
                              style={{ marginLeft: "50%" }}
                              onClick={() => handleAddFields(index)}
                            >
                              Add{" "}
                            </Button>
                          )}
                        </>
                      )
                    )}
                  </>
                )}
                <Form.Group className="form-btn">
                  <CommonButton disabled={!orderForm.dirty}
                    type="submit"
                    className="w-100 primary-btn" label={id === "new" ? "Create order " : "Save and update"} loader={loader}
                  />
                </Form.Group>
                {id !== "new" &&
                  ordersInfo.status !== ORDER_STATUS.CANCELLED &&
                  ordersInfo.status !== ORDER_STATUS.COMPLETED &&
                  ordersInfo.status !== ORDER_STATUS.WORKING && (
                    <CommonButton label="Cancel Order" loader={loader} disabled={loader} variant="danger"
                      onClick={() => handleCancelOrder()} />
                  )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="bread-crumb"></div> */}
    </section>
  );
}

export default EditOrder;
