import React from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { DB_COLLECTION, USER_TYPE } from "../../common/constants";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../configs/fireBaseConfig";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import InputField from "../../common/InputField";
import * as yup from "yup";
import { VALIDATION } from "../../common/validation";
import { toastError } from "../../common/utils";

function SignUp() {
  const schema = yup.object().shape({
    name: VALIDATION.TEXT_INPUT_NAME,
    email: VALIDATION.EMAIL,
    password: VALIDATION.PASSWORD,
    confirmPassword: VALIDATION.CONFIRM_PASSWORD,
    role: yup.string().required(),
  });

  const SignUpForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: null,
    },
    onSubmit: (values) => {
      onFinish(values);
    },
    validationSchema: schema,
  });

  const onFinish = async (formData) => {
    try {
      const auth = getAuth();
      const res = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const userData = res.user;
      await setDoc(doc(db, DB_COLLECTION.USERS, userData.uid), {
        uid: userData.uid,
        name: formData.name,
        role: formData.role,
        email: userData.email,
        emailVerified: userData.emailVerified,
        createdAt: userData.metadata.createdAt,
      });
    } catch (error) {
      /* show message user already exist */
      let errorMsg
      if (error.code === "auth/email-already-in-use") {
        errorMsg = "Email is already registered"
      }
      toastError(errorMsg)
    }
  };

  return (
    <section className="login-section">
      <Container>
        <Row>
          <Col sm={12} md={{ span: 6, offset: 3 }}>
            <div className="form-section">
              <div className="title-wrap">
                <h2>Sign up</h2>
              </div>
              <Form
                name="basic"
                onSubmit={SignUpForm.handleSubmit}
                autoComplete="off"
              >
                <InputField
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  onChange={SignUpForm.handleChange}
                  isValid={SignUpForm.touched.name && !SignUpForm.errors.name}
                  isInvalid={SignUpForm.errors.name}
                />
                <InputField
                  label="Email"
                  name="email"
                  type="email"
                  onChange={SignUpForm.handleChange}
                  placeholder="Email"
                  isValid={SignUpForm.touched.email && !SignUpForm.errors.email}
                  isInvalid={SignUpForm.errors.email}
                />
                <InputField
                  label="Password"
                  name="password"
                  type="password"
                  onChange={SignUpForm.handleChange}
                  placeholder="Password"
                  isValid={
                    SignUpForm.touched.password && !SignUpForm.errors.password
                  }
                  isInvalid={SignUpForm.errors.password}
                />
                <InputField
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onChange={SignUpForm.handleChange}
                  placeholder="Confirm Password"
                  isInvalid={SignUpForm.errors.confirmPassword}
                />

                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mt-4">
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      label="Admin"
                      name="role"
                      value={USER_TYPE.ADMIN}
                      type={type}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      label="Supervisor"
                      name="role"
                      type={type}
                      value={USER_TYPE.SUPERVISOR}
                      id={`inline-${type}-2`}
                    />
                    <Form.Check
                      onChange={SignUpForm.handleChange}
                      inline
                      name="role"
                      value={USER_TYPE.WARPER}
                      label="Warper"
                      type={type}
                      id={`inline-${type}-3`}
                    />
                  </div>
                ))}
                <Form.Group className="form-btn">
                  <Button type="submit" className="w-100 primary-btn">
                    Sign up
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default SignUp;
