import React, { useEffect, useState } from "react";
import { db } from "../../configs/fireBaseConfig";
import {
  DB_COLLECTION,
  LIMIT,
  ROUTES,
  SORT_TYPE,
  USER_TYPE,
} from "../../common/constants";
import {
  collection,
  count,
  getAggregateFromServer,
  getDocs,
  query,
  limit,
  where,
  startAfter,
  startAt,
  orderBy,
} from "firebase/firestore";
import {
  Badge,
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb/BreadCrumb";
import InputField from "../../common/InputField";
const userCollectionRef = collection(db, DB_COLLECTION.USERS);
function ListUser() {
  const [userList, setUserList] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [lastDocument, setLastDocument] = useState(null);
  const [isEnds, setIsEnds] = useState(false);
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async (search = null) => {
    try {
      const conditions = [orderBy("createdAt", SORT_TYPE.ASCENDING)];
      if (search !== null) {
        conditions.push(where("name", ">=", search));
      } else {
        if (lastDocument) {
          conditions.push(startAfter(lastDocument));
        }
      }
      conditions.push(limit(LIMIT));
      const prepareQuery = query(userCollectionRef, ...conditions);
      const users = await getDocs(prepareQuery);
      setIsEnds(users.docs.length < LIMIT);
      setLastDocument(users.docs[users.docs.length - 1]);
      let prepareList = [];
      users.forEach((doc) => {
        prepareList.push(doc.data());
      });
      setUserList(prepareList);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    if (search !== "") {
      getUsers(search);
    } else {
      getUsers();
    }
  };

  const roleBadge = (role) => {
    switch (role) {
      case USER_TYPE.ADMIN:
        return (
          <Badge pill bg="dark">
            Admin
          </Badge>
        );
        break;
      case USER_TYPE.SUPERVISOR:
        return (
          <Badge pill bg="secondary">
            Supervisor
          </Badge>
        );
        break;
      case USER_TYPE.WARPER:
        return (
          <Badge pill bg="warning" text="dark">
            Warper
          </Badge>
        );
        break;
    }
  };

  return (
    <section className="page-section">
      {/* <BreadCrumb pageTitle="User List" /> */}
      <div className="data-section">
        <Container fluid>
          <Row>
            <Col>
              <div className="data-table-header">
                <h4>Users</h4>
                <Button
                  onClick={() =>
                    navigate(`${ROUTES.LIST_USER}${ROUTES.EDIT_USER}/new`)
                  }
                >
                  Create user
                </Button>
              </div>
            </Col>
            <Col sm={12}>
              <div className="table-section">
                <InputField
                  placeholder="Search user"
                  className="search-input"
                  onChange={handleSearch}
                />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th colSpan={2} width="20%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList.length ? (
                      userList.map((item) => (
                        <tr key={item.uid}>
                          <td>{item.name}</td>
                          <td>{roleBadge(item.role)}</td>
                          <td colSpan={2}>
                            <Link
                              to={`${ROUTES.LIST_USER}${ROUTES.EDIT_USER}/${item.uid}`}
                            >
                              <Button className="d-table-btn">
                                <i class="fa-regular fa-pen-to-square"></i>
                                Edit
                              </Button>
                            </Link>
                            {/* <Button className="d-table-btn">
                              <i class="fa-regular fa-trash-can"></i> Delete
                            </Button> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={3}>No data found</td>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default ListUser;
