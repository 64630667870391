import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function CommonButton(props) {
    return (
        <Button {...props} >
            {props.loader ? <><Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />{" "}
                {props?.label} </> : props?.label}
        </Button>
    )
}

export default CommonButton