import * as Yup from "yup";

export const VALIDATION = {
  EMAIL: Yup.string()
    .email("Please enter valid email")
    .required("Please enter email"),
  TEXT_INPUT_NAME: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  PASSWORD: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 6 characters long"),
  CONFIRM_PASSWORD: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  NUMBER_INPUT: (msg) => Yup.number().required(msg)
};
