import React from "react";
import { Col, Form } from "react-bootstrap";

function InputField(props) {
  return (
    <Form.Group key={props.name} className="mb-3">
      {props.label && (
        <Form.Label column sm="12">
          {props.label}
        </Form.Label>
      )}
      <Col sm="12">
        <Form.Control
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          isValid={props.isValid}
          isInvalid={props.isInvalid}
          disabled={props.disabled}
          className={props?.className}
          as={props?.as}
          style={props?.style}
        />
        {props.isInvalid && (
          <Form.Control.Feedback type="invalid">
            {props.isInvalid}
          </Form.Control.Feedback>
        )}
      </Col>
    </Form.Group>
  );
}

export default InputField;
