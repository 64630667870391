import { ROUTES, USER_TYPE } from "./common/constants";

const ADMIN_PREVENT_ROUTES = [];
const SUPERVISOR_PREVENT_ROUTES = [ROUTES.LIST_USER];
const WARPER_PREVENT_ROUTES = [ROUTES.LIST_USER, ROUTES.LIST_ORDER];

export default function isUserCanGO(userRole, route) {
    let preventableRoutes = []
    if (userRole === USER_TYPE.ADMIN) {
        preventableRoutes = ADMIN_PREVENT_ROUTES;
    } else if (userRole === USER_TYPE.SUPERVISOR) {
        preventableRoutes = SUPERVISOR_PREVENT_ROUTES;
    } else if (userRole === USER_TYPE.WARPER) {
        preventableRoutes = WARPER_PREVENT_ROUTES;
    }
    return !preventableRoutes.includes(route)
}