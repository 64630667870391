import {
    collection,
    getDocs,
    limit,
    orderBy,
    where,
    startAfter,
    query,
} from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../configs/fireBaseConfig";
import {
    DB_COLLECTION,
    LIMIT,
    ORDER_STATUS,
    REGULAR_DATE_WITH_TIME_FORMAT,
    ROUTES,
    SORT_TYPE,
} from "../../common/constants";
import InputField from "../../common/InputField";
import { useNavigate } from "react-router-dom";
import {
    Container,
    Button,
    Col,
    Row,
    Table,
    Badge,
    Form,
} from "react-bootstrap";
import dayjs from "dayjs";

function TaskList() {
    const [orderList, setOrderList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [lastDocument, setLastDocument] = useState(null);
    const [isEnds, setIsEnds] = useState(false);
    const [filters, setFilters] = useState([ORDER_STATUS.PLANNED, ORDER_STATUS.WORKING]);

    const navigate = useNavigate();

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = async (filterAry = null, search = null) => {
        try {
            const prepareFilter = filterAry ? filterAry : filters;
            const conditions = [orderBy("createdAt", SORT_TYPE.DESCENDING)];
            if (prepareFilter.length) {
                conditions.push(where("status", "in", prepareFilter));
            }
            if (search !== null) {
                conditions.push(where("party_name", ">=", search));
            }
            if (!filterAry && !search && lastDocument) {
                conditions.push(startAfter(lastDocument));
            }
            conditions.push(limit(LIMIT));
            const prepareQuery = query(
                collection(db, DB_COLLECTION.ORDERS),
                ...conditions
            );
            const orders = await getDocs(prepareQuery);
            let prepareList = [];
            setIsEnds(orders.docs.length < LIMIT);
            setLastDocument(orders.docs[orders.docs.length - 1]);
            orders.forEach((doc) => {
                prepareList.push({ id: doc.id, ...doc.data() });
            });
            setOrderList(prepareList);
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (e) => {
        const search = e.target.value;
        if (search !== "") {
            getOrders(null, search);
        } else {
            getOrders();
        }
    };

    const orderStatusBadge = (status) => {
        switch (status) {
            case ORDER_STATUS.WORKING:
                return <Badge bg="info">In Progress</Badge>;
                break;
            case ORDER_STATUS.PLANNED:
                return <Badge bg="secondary">Planned</Badge>;
                break;
            case ORDER_STATUS.PENDING:
                return <Badge bg="dark">Arrived</Badge>;
                break;
            case ORDER_STATUS.COMPLETED:
                return <Badge bg="success">Completed</Badge>;
                break;
            case ORDER_STATUS.CANCELLED:
                return <Badge bg="danger">Cancelled</Badge>;
                break;
        }
    };

    const handleChange = (filterItem) => {
        let filterItems = filters;
        let bool = filterItems.includes(filterItem);
        if (!filterItems.includes(filterItem)) {
            filterItems.push(filterItem);
        } else {
            filterItems.splice(filterItems.indexOf(filterItem), 1);
        }
        getOrders(filterItems);
        setFilters([...filterItems]);
    };

    return (
        <section className="page-section">
            <div className="data-section">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="data-table-header">
                                <h4>Task</h4>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="table-header-options">
                                <div className="table-header-options">
                                    <Form.Check
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id="Planned"
                                        key="Planned"
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            onChange={() => {
                                                handleChange(ORDER_STATUS.PLANNED);
                                            }}
                                            checked={filters.includes(ORDER_STATUS.PLANNED)}
                                        />
                                        <Form.Check.Label>Planned</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">
                                            You did it!
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                    <Form.Check
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id="Working"
                                        key="Working"
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            onChange={() => {
                                                handleChange(ORDER_STATUS.WORKING);
                                            }}
                                            checked={filters.includes(ORDER_STATUS.WORKING)}
                                        />
                                        <Form.Check.Label>Working</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">
                                            You did it!
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                    <Form.Check
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id="Completed"
                                        key="Completed"
                                    >
                                        <Form.Check.Input
                                            type="checkbox"
                                            onChange={() => {
                                                handleChange(ORDER_STATUS.COMPLETED);
                                            }}
                                            checked={filters.includes(ORDER_STATUS.COMPLETED)}
                                        />
                                        <Form.Check.Label>Completed</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">
                                            You did it!
                                        </Form.Control.Feedback>
                                    </Form.Check>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <InputField
                                placeholder="Search order"
                                className="search-input"
                                onChange={handleSearch}
                            />
                            <div className="table-section">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Company Name</th>
                                            <th>Yarn Details</th>
                                            <th>Status</th>
                                            <th>Start Time</th>
                                            <th>Completing Time</th>
                                            <th colSpan={2} width="20%">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderList.length ? (
                                            orderList.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.party_name}</td>
                                                    <td>{item.yarn_type}</td>
                                                    <td>{orderStatusBadge(item.status)}</td>
                                                    <td>{item.orderStartTime ? dayjs(item.orderStartTime).format(REGULAR_DATE_WITH_TIME_FORMAT) : "-"}</td>
                                                    <td>{item.orderEndsTime ? dayjs(item.orderEndsTime).format(REGULAR_DATE_WITH_TIME_FORMAT) : "-"}</td>
                                                    <td colSpan={2}>

                                                        <Button
                                                            className="d-table-btn"
                                                            onClick={() =>
                                                                navigate(
                                                                    `${ROUTES.TASK_LIST}${ROUTES.DETAIL_TASK}/${item.id}`
                                                                )
                                                            }
                                                        >
                                                            <i class="fa-regular fa-pen-to-square"></i>
                                                            Details
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <td colSpan={8}>No data found</td>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default TaskList;
