export const TOKEN = "ACCESS_TOKEN";

export const ROUTES = {
  HOME: "/",
  LOGIN: "/",
  SIGN_UP: "/signup",
  DASHBOARD: "/dashboard",
  EDIT_USER: "/edit",
  LIST_USER: "/users",
  LIST_ORDER: "/orders",
  EDIT_ORDER: "/edit",
  WARPING_PLANS: "/warping-plans",
  WARPING_PLANS_DETAILS: "/warping-plans-details",
  TASK_LIST: "/tasks",
  DETAIL_TASK: '/detail'
};
export const LIMIT = 10;
export const BREAKPOINT = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

export const USER_TYPE = {
  ADMIN: "ADMIN",
  SUPERVISOR: "SUPERVISOR",
  WARPER: "WARPER",
};

export const ORDER_STATUS = {
  PENDING: "PENDING",
  PLANNED: "PLANNED",
  WORKING: "WORKING",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const DB_COLLECTION = {
  USERS: "users",
  ORDERS: "orders",
  COUNTER: "counter",
  COUNTER_REFERENCE: "invoice",
};

export const SORT_TYPE = {
  ASCENDING: "asc",
  DESCENDING: "desc",
}

export const REGULAR_DATE_FORMAT = "DD/MM/YYYY"
export const REGULAR_DATE_WITH_TIME_FORMAT = "DD/MM/YYYY - h:m:A"
