import React, { useEffect, useState } from "react";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { ROUTES } from "../common/constants";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { ProtectedLayout } from "../authComponent/authContext";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";

function LayoutUser(props) {
  const [isSideBarOpen, setIsSideBarOpen] = useState(window.innerWidth >= 767)
  const location = useLocation()

  useEffect(() => {
    if (window.innerWidth <= 767 && isSideBarOpen) {
      setIsSideBarOpen(!isSideBarOpen)
    }
  }, [location.pathname])


  return (
    <ProtectedLayout>
      <section className="main-layout">
        <Sidebar handleSideBar={() => setIsSideBarOpen(!isSideBarOpen)} isSideBarOpen={isSideBarOpen} />
        <section className="main-section">
          <Header handleSideBar={() => setIsSideBarOpen(!isSideBarOpen)} />
          <section className="wrap-container">
            <Outlet />
          </section>
          {/* <Footer /> */}
        </section>
      </section>
    </ProtectedLayout>
  );
}
export default LayoutUser;
