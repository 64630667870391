"use client"
import React, { useState, useEffect } from 'react'
import { useContext } from 'react';
import packageJson from '../../package.json';
import { DB_COLLECTION, TOKEN } from '../common/constants';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db, firebaseAuth } from '../configs/fireBaseConfig';
import { toastInfo } from '../common/utils';
import { Spinner } from 'react-bootstrap';

const AuthContext = React.createContext(null);

function AuthProvider(props) {
    console.log(`⚡ v${packageJson.version}`)
    const [contextValue, setContextValue] = useState({ isAuthenticated: false, userInfo: {} });

    let value = { contextValue, setContextValue };
    return (
        <>
            <AuthContext.Provider value={value} >
                {props.children}
            </AuthContext.Provider>
        </>
    )
}


function useAuth() {
    return useContext(AuthContext)
}

function ProtectedLayout(props) {
    const navigate = useNavigate();
    const { contextValue, setContextValue } = useAuth();
    const [globalLoader, setGlobalLoader] = useState(true)

    useEffect(() => {
        let token = localStorage.getItem(TOKEN)
        if (contextValue.isAuthenticated === false && token) {
            fetchUserInfo();
        } else if (!token) {
            localStorage.removeItem(TOKEN);
            // navigate(ROUTES.MANAGE_USER);
        }
    }, []);

    const fetchUserInfo = async () => {
        onAuthStateChanged(firebaseAuth, async (userData) => {
            if (userData) {
                const query = doc(db, DB_COLLECTION.USERS, userData.uid);
                const document = await getDoc(query);
                if (document.exists()) {
                    const userData = document.data();
                    setContextValue({
                        userInfo: {
                            email: userData.email,
                            emailVerified: userData.emailVerified,
                            uid: userData.uid,
                            name: userData.name,
                            role: userData.role,
                        },
                        isAuthenticated: true
                    });
                    setGlobalLoader(false);
                }
            } else {
                setGlobalLoader(false);
                toastInfo("You are logged out")
            }
        });
    }

    console.log("contextValue", contextValue);
    // if (globalLoader) {
    //     <Spinner animation="grow" />
    // }

    return (
        <>{contextValue.isAuthenticated && props.children} </>
    )
}


export { useAuth, AuthProvider, ProtectedLayout }
