import React from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import "./Header.scss";
import { ROUTES } from "../../common/constants";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Header = ({ handleSideBar }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        navigate(ROUTES.LOGIN);
      });
  };

  return (
    <Navbar expand="lg" className="navbar-section">
      <Container fluid>
        <div className="nav-header">
          <Button onClick={handleSideBar} className="menu-btn">
            <i class="fa-solid fa-bars"></i>
          </Button>
          <Button
            variant="danger"
            onClick={() => handleLogout()}
            className="menu-btn"
          >
            Logout
          </Button>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
