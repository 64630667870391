import React from "react";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { ROUTES, USER_TYPE } from "../../common/constants";
import { Button } from "react-bootstrap";
import isUserCanGO from "../../userControl";
import { useAuth } from "../../authComponent/authContext";

const Sidebar = ({ isSideBarOpen, handleSideBar }) => {
  const state = useAuth();
  const userRole = state.contextValue.userInfo.role;
  let location = useLocation();
  location = location.pathname;
  const sidebarItems = [
    {
      title: "Manage Users",
      icon: "fa-solid fa-user",
      link: ROUTES.LIST_USER,
    },
    {
      title: "Manage Orders",
      icon: "fa-regular fa-newspaper",
      link: ROUTES.LIST_ORDER,
    },
    {
      title: "Manage Tasks",
      icon: "fa-solid fa-list-check",
      link: ROUTES.TASK_LIST,
    },
  ];

  let preparedSidebarItems = sidebarItems.filter((item) =>
    isUserCanGO(userRole, item.link)
  );

  return (
    <>
      <section
        className={`sidebar-section ${!isSideBarOpen ? "open-sidebar" : ""}`}
      >
        <div className="sidebar-logo">
          <Link className="logo-link">Warpex</Link>
          <Button onClick={handleSideBar} className="menu-btn">
            <i class="fa-solid fa-bars"></i>
          </Button>
        </div>
        <div className="sidebar-menu-panel">
          <ul className="menu-section">
            {preparedSidebarItems.map((item) => (
              <li>
                <Link
                  className={`menu-link ${location == item.link ? "active" : ""
                    }`}
                  to={item.link}
                >
                  <label>
                    <i class={item.icon}></i> {item.title}
                  </label>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
