import React, { useEffect, useState } from "react";
import { db } from "../../configs/fireBaseConfig";
import {
  DB_COLLECTION,
  ORDER_STATUS,
  ROUTES,
  USER_TYPE,
} from "../../common/constants";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router";
import { toastError, toastSuccess } from "../../common/utils";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../authComponent/authContext";
import Modal from "react-bootstrap/Modal";
import dayjs from "dayjs";

function TaskDetails() {
  const navigate = useNavigate();
  const { contextValue } = useAuth();
  const userRole = contextValue.userInfo.role;
  let { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [ordersInfo, setOrdersInfo] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    const query = doc(db, DB_COLLECTION.ORDERS, id);
    const document = await getDoc(query);
    if (document.exists()) {
      const order = document.data();
      setOrdersInfo(order);
    }
    setLoader(false);
    setButtonLoader(false);
  };

  const startTask = async () => {
    setButtonLoader(true);
    await setDoc(
      doc(db, DB_COLLECTION.ORDERS, id),
      {
        status: ORDER_STATUS.WORKING,
        orderStartTime: Date.now(),
      },
      { merge: true }
    );
    toastSuccess("Task started successfully");
  };

  const completeTask = async () => {
    // make task as completed
    await setDoc(
      doc(db, DB_COLLECTION.ORDERS, id),
      {
        status: ORDER_STATUS.COMPLETED,
        orderEndsTime: Date.now(),
      },
      { merge: true }
    );
    fetchOrderDetails();
    toastSuccess("Task completed successfully");
    // and give the invoice number popup
  };

  if (loader) {
    return <div>loader</div>;
  }

  return (
    <section className="page-section">
      <div className="data-section">
        <Container fluid>
          <Row>
            <div className="col-sm-12">
              <div className="data-table-header">
                <h4>Warping Details</h4>
                <Link to={ROUTES.TASK_LIST}>
                  <Button>Back</Button>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="details-card">
                <h3 className="details-title">Company Details</h3>
                <div className="details-box">
                  <h4>Name :</h4>
                  <p>{ordersInfo.party_name}</p>
                </div>
                <div className="details-box">
                  <h4>Address :</h4>
                  <p>{ordersInfo.address}</p>
                </div>
                {ordersInfo.status === ORDER_STATUS.COMPLETED && (
                  <div className="details-box">
                    <h4>Order Id:</h4>
                    <p>{ordersInfo.invoice_id}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="details-card">
                <h3 className="details-title">Requirement</h3>
                <div className="details-box">
                  <h4>Yarn type :</h4>
                  <p>{ordersInfo.yarn_type}</p>
                </div>
                <div className="details-box">
                  <h4>Address :</h4>
                  <p>{ordersInfo.total_beams}</p>
                </div>
                <div className="details-box">
                  <h4>Name :</h4>
                  <p>{ordersInfo.total_ends}</p>
                </div>
                <div className="details-box">
                  <h4>Address :</h4>
                  <p>{ordersInfo.mtr}</p>
                </div>
                <div className="details-box">
                  <h4>Name :</h4>
                  <p>{ordersInfo.pano}</p>
                </div>
                <div className="details-box">
                  <h4>Address :</h4>
                  <p>{ordersInfo.supervisor_remarks}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="details-card">
                <h3 className="details-title">Beam Plan</h3>
                <div className="details-sect">
                  {ordersInfo.beam_details.map((item, index) => (
                    <div className="card-box">
                      <div className="details-box">
                        <h4>Beams :</h4>
                        <p>{item.beams}</p>
                      </div>
                      <div className="details-box">
                        <h4>Ends :</h4>
                        <p>{item.ends}</p>
                      </div>
                      <div className="details-box">
                        <h4>spools :</h4>
                        <p>{item.spools}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-sm-4 offset-md-6">
              <div>
                {ordersInfo.status === ORDER_STATUS.PLANNED && (
                  <Button
                    onClick={() => startTask()}
                    disabled={
                      ordersInfo.status === ORDER_STATUS.WORKING || buttonLoader
                    }
                  >
                    Start
                  </Button>
                )}
                {ordersInfo.status === ORDER_STATUS.WORKING && (
                  <Button
                    onClick={() => completeTask()}
                    disabled={
                      ordersInfo.status === ORDER_STATUS.COMPLETED ||
                      buttonLoader
                    }
                  >
                    End
                  </Button>
                )}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default TaskDetails;
