import { toast } from "react-toastify";

const toastOptions = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
  //   transition: "Bounce",
};

export const toastSuccess = (message) => {
  return toast.success(message, toastOptions);
};

export const toastError = (message, error = null) => {
  // const msg = message ? message : "Something went wrong"
  if (message) {
    return toast.error(message, toastOptions);
  } else {
    console.log(error)
  }
};

export const toastInfo = (message) => {
  return toast.info(message, toastOptions);
};
