import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import Login from "./pages/login/Login";
import { ROUTES } from "./common/constants";
import SignUp from "./pages/signup/SignUp";
import { ProtectedLayout } from "./authComponent/authContext";
import ListUser from "./pages/manageUser/ListUser";
import EditUser from "./pages/manageUser/EditUser";
import LayoutUser from "./component/LayoutNavigation";
import EditOrder from "./pages/manageOrder/EditOrder";
import ListOrder from "./pages/manageOrder/ListOrder";
import TaskList from "./pages/task/TaskList";
import TaskDetails from "./pages/task/TaskDetails";

export const router = createBrowserRouter([
  {
    path: "/",
    // element: <LayoutNavigation />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: ROUTES.SIGN_UP,
        element: <SignUp />,
      },
    ],
  },
  {
    // path: "/",
    element: <LayoutUser />,
    children: [
      {
        path: ROUTES.LIST_USER,
        element: <ListUser />,
      },
      {
        path: `${ROUTES.LIST_USER}${ROUTES.EDIT_USER}/:id`,
        element: <EditUser />,
      },
      {
        path: ROUTES.LIST_ORDER,
        element: <ListOrder />,
      },
      {
        path: `${ROUTES.LIST_ORDER}${ROUTES.EDIT_ORDER}/:id`,
        element: <EditOrder />,
      },
      {
        path: ROUTES.TASK_LIST,
        element: <TaskList />,
      },
      {
        path: `${ROUTES.TASK_LIST}${ROUTES.DETAIL_TASK}/:id`,
        element: <TaskDetails />,
      },
    ],
  },
]);
