import React, { useState } from "react";
import { browserLocalPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import {
  DB_COLLECTION,
  ROUTES,
  TOKEN,
  USER_TYPE,
} from "../../common/constants";
import { useAuth } from "../../authComponent/authContext";
import { db, firebaseAuth } from "../../configs/fireBaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { VALIDATION } from "../../common/validation";
import InputField from "../../common/InputField";
import { toastError, toastInfo, toastSuccess } from "../../common/utils";
import CommonButton from "../../common/CommonButton";

function Login() {
  const { setContextValue } = useAuth();
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false)

  const schema = yup.object().shape({
    email: VALIDATION.EMAIL,
    password: VALIDATION.PASSWORD,
  });

  const signInForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values, null, 2);
      onFinish(values);
    },
    validationSchema: schema,
  });

  const onFinish = async (values) => {
    setButtonLoader(true)
    // const auth = getAuth()
    try {
      await setPersistence(firebaseAuth, browserLocalPersistence)
      const res = await signInWithEmailAndPassword(
        firebaseAuth,
        values.email,
        values.password
      );
      const userData = res.user;
      /* check this user in firestore by uid and get further user data and add to context  */
      localStorage.setItem(TOKEN, userData.accessToken);
      const query = doc(db, DB_COLLECTION.USERS, userData.uid);
      const document = await getDoc(query);
      if (document.exists()) {
        const userData = document.data();
        setContextValue({
          userInfo: {
            email: userData.email,
            emailVerified: userData.emailVerified,
            uid: userData.uid,
            name: userData.name,
            role: userData.role,
          },
          isAuthenticated: true
        });
        var userRole = userData.role;
      } else {
        toastInfo('User is not registered')
      }
      toastSuccess("Login successfully")
      if (userRole === USER_TYPE.WARPER) {
        navigate(ROUTES.TASK_LIST)
      } else {
        navigate(ROUTES.LIST_ORDER)
      }
      setButtonLoader(false)
    } catch (error) {
      let errorMsg;
      if (error.code === "auth/invalid-credential") {
        errorMsg = "Email or password is wrong";
      } else if (error.code === "auth/user-not-found") {
        errorMsg = "User is not registered";
      }
      toastError(errorMsg, error);
      setButtonLoader(false)
    }
  };

  return (
    <section className="login-section">
      <Container>
        <Row>
          <Col sm={12} md={{ span: 6, offset: 3 }}>
            <div className="form-section">
              <div className="title-wrap">
                <h2>Login</h2>
              </div>
              <Form
                name="basic"
                onSubmit={(e) => {
                  e.preventDefault();
                  signInForm.handleSubmit();
                }}
                autoComplete="off"
              >
                <InputField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={signInForm.handleChange}
                  isValid={signInForm.touched.email && !signInForm.errors.email}
                  isInvalid={signInForm.errors.email}
                />
                <InputField
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={signInForm.handleChange}
                  isValid={
                    signInForm.touched.password && !signInForm.errors.password
                  }
                  isInvalid={signInForm.errors.password}
                />
                <Form.Group className="form-btn">
                  <CommonButton label="Login" type="submit" loader={buttonLoader} disabled={buttonLoader} className="w-100 primary-btn" />
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Login;
